import { useState, useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Navigation from "./components/Navigation/Navigation/Navigation"
import Footer from "./components/Footer/Footer"
import PublicRoute from "./components/PublicRoute/PublicRoute"
import SellerSignup from "./views/SellerSignup/SellerSignup";
import Home from "./views/Home/Home"
import Cgu from "./views/Cgu/Cgu"

const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  })
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          }
        />
        <Route
          path="/seller/signup"
          element={
            <PublicRoute>
              <SellerSignup />
            </PublicRoute>
          }
        />
        <Route
          path="/cgu"
          element={
            <PublicRoute>
              <Cgu />
            </PublicRoute>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
