import { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import Offcanvas from "react-bootstrap/Offcanvas"
import Nav from "react-bootstrap/Nav"
import { Link, useNavigate } from "react-router-dom"
import "./PublicNav.scss"
import { Button } from "react-bootstrap"
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton"

const PublicNav = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  let navigate = useNavigate()
  return (
    <>
      <Navbar.Brand as={Link} className="public-nav__brand" to="/">
        LIVESHOPP.FR
      </Navbar.Brand>
      <Navbar.Toggle
        onClick={() => setShowOffCanvas(true)}
        className="public-nav__toggle"
      />
      {/* desktop navbar */}
      <Navbar.Collapse
        className="public-nav__desktop"
        id="responsive-navbar-nav"
      >
        <Nav className="public-nav__desktop__navbar">
          <Nav.Link
            className="public-nav__desktop__navbar__link"
            href="mailto:application@liveshopp.fr"
          >
            Nous contacter
          </Nav.Link>
          <PrimaryButton
            variant="red-liveshopp"
            size="small"
            text="Devenir un vendeur"
            onClick={() => navigate("/seller/signup")}
          />
        </Nav>
      </Navbar.Collapse>
      {/* mobile navbar */}
      <Navbar.Offcanvas
        show={showOffCanvas}
        className="public-nav__mobile"
        placement="bottom"
      >
        <Offcanvas.Header
          className="public-nav__mobile__header"
          closeButton
          onClick={() => setShowOffCanvas(false)}
        ></Offcanvas.Header>
        <Offcanvas.Body className="public-nav__mobile__body">
          <Nav className="public-nav__mobile__body__nav">
            <Nav.Link className="public-nav__mobile__body__nav__link" href="/">
              Accueil
            </Nav.Link>
            <Nav.Link className="public-nav__mobile__body__nav__link" href="mailto:application@liveshopp.fr">
              Nous contacter
            </Nav.Link>
            <Nav.Link className="public-nav__mobile__body__nav__link become-a-seller" href="/seller/signup">
              Devenir vendeur
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  )
}

export default PublicNav
