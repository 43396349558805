import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "react-router-dom"
import "./Footer.scss"

const Footer = () => {
  return (
    <>
      <Container className="footer">
        <Row className="footer__socials">
          {/*<Row className="footer__socials__follow-us">Suivez-nous !</Row>*/}
          <Row className="footer__socials__section">
            <Col className="footer__socials__section__icons">
              <a
                className="footer__socials__section__icons__twitter"
                href="https://twitter.com/liveshopp_fr"
                target="blank"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
            </Col>
          </Row>
        </Row>
        <Row className="footer">
          <Col className="footer__legal">
            <Row className="footer__legal__infos">
              <Col
                className="footer__legal__infos__contact"
              >
                application@liveshopp.com
              </Col>
              <Col
                as={Link}
                className="footer__legal__infos__contact"
                to="/cgu"
              >
                Conditions générales d'utilisation
              </Col>
            </Row>
          </Col>
          <Col className="footer__copyright">
            © 2023 - Liveshoop. Tous droits réservés.
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer
