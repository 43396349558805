const sellerSignupForm = {
  title: "Seller signup form",
  description: "Selller signup form",
  type: "object",
  fields: {
    firstname: {
      label: "Prénom",
      name: "firstname",
      description: "firstname",
      type: "text",
      default: "",
      placeholder: "Prénom",
      helper: "",
      validation: {
        required: "Indiquez votre prénom",
        pattern: {
          value: /^[a-zA-Z\u00C0-\u00FF]*$/,
          message: "Veuillez indiquez un prénom valide",
        },
      },
    },
    name: {
      label: "Nom",
      name: "lastname",
      description: "lastname",
      type: "text",
      default: "",
      placeholder: "Nom",
      helper: "",
      validation: {
        required: "Indiquez votre nom",
        pattern: {
          value: /^[a-zA-Z\u00C0-\u00FF]*$/,
          message: "Veuillez indiquez un nom valide",
        },
      },
    },
    role: {
      label: "Rôle dans l'entreprise",
      name: "role",
      description: "Rôle dans l'entreprise",
      type: "select",
      options: [
        { option: "Sélectionée un rôle", disabled: true, selected: true },
        {
          option: "Direction", disabled: false,
        },
        {
          option: "Marketing",
          disabled: false,
        },
        { option: "Commercial", disabled: false },
        { option: "R&D", disabled: false }],
      default: "",
      placeholder: "Rôle",
      helper: "",
      validation: {
        required: "Indiquez votre rôle dans l'entreprise.",
      },
    },
    phone: {
      label: "Téléphone",
      name: "phone",
      description: "User phone number",
      type: "phone",
      default: "",
      placeholder: "N° de téléphone",
      validation: {
        required: "Indiquez votre n° de téléphone",
        minLength: {
          value: 10,
          message: "N° de téléphone invalide.",
        },
      },
    },
    email: {
      label: "Email",
      name: "email",
      description: "Email",
      type: "email",
      default: "",
      placeholder: "Email",
      validation: {
        required: "Indiquez votre Email",
      },
    },
    company_name: {
      label: "Nom de l'entreprise",
      name: "company_name",
      description: "Company name",
      type: "text",
      default: "",
      placeholder: "Nom de l'entreprise",
      helper: "",
      validation: {
        required: "Indiquez le nom de l'entreprise.",
      },
    },
    business_activity: {
      label: "Secteur d'activité de l'entreprise",
      name: "business_activity",
      description: "Secteur d'activité de l'entreprise",
      type: "select",
      options: [{ option: "Sélectionné un secteur d'activité", disabled: true, selected: true }, {
        option: "Sport", disabled: false,
      }, {
        option: "Mode", disabled: false,
      }, {
        option: "High-Tech", disabled: false,
      }, { option: "Antiquaire", disabled: false }],
      default: "",
      placeholder: "Secteur d'activité",
      helper: "",
      validation: {
        required: "Indiquez le secteur d'activité de l'entreprise.",
      },
    },
    social_media_platform: {
      label: "Réseau social",
      name: "social_media_platform",
      description: "Réseau social",
      type: "select",
      options: [{ option: "Sélectionné un réseau social", disabled: true, selected: true }, {
        option: "Instagram", disabled: false,
      }, {
        option: "Twitter", disabled: false,
      }, { option: "Facebook", disabled: false }, { option: "TikTok", disabled: false }, {
        option: "Snapchat",
        disabled: false,
      }],
      default: "",
      placeholder: "Réseau social",
      helper: "",
      validation: {
        required: "Indiquez un réseau social.",
      },
    },
  },
}

export default sellerSignupForm
