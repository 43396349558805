import { useState } from "react"
import { Container, Row, Col, Image, Card, Accordion } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import PrimaryButton from "../../components/Button/PrimaryButton/PrimaryButton"
import liveshopp_user from "../../assets/images/liveshopp_user.jpeg"
import liveshopp_live from "../../assets/images/liveshopp_live.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import {
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons"
import liveshopp_app_store_svg from "../../assets/images/liveshopp_app_store.png"
import liveshopp_google_play_store_icon from "../../assets/images/liveshopp_google_play_store_icon.png"
import "./Home.scss"

const Home = () => {
  let navigate = useNavigate()
  return (
    <Container className="home">
      <Row className="home__container">
        <Col className="home__container__headlines">
          <Row className="home__container__headlines__headline">
            Vendez et achetez en live.
          </Row>
          <Row className="home__container__headlines__sub-headline">
            Découvre de nouveau produits à travers une communauté de passionnées.
          </Row>
          <Row className="home__container__headlines__ctas">
            <Row className="home__container__headlines__ctas__app-store">
              <Link className="home__container__headlines__ctas__app-store__link"
                    to="https://apps.apple.com/fr/app/liveshopp/id6448577391" target="_blank" rel="noreferrer">
                <Image
                  className="home__container__headlines__ctas__app-store__link__button"
                  src={liveshopp_app_store_svg}
                  rounded
                />
              </Link>
            </Row>
            <Row className="home__container__headlines__ctas__app-store">
              <Link className="home__container__headlines__ctas__app-store__link"
                    to="" target="_blank" rel="noreferrer">
                <Image
                  className="home__container__headlines__ctas__app-store__link__button"
                  src={liveshopp_google_play_store_icon}
                  rounded
                />
              </Link>
            </Row>
          </Row>
        </Col>
        <Col className="home__container__image">
          <Row className="home__container__image__desktop">
            <img className="home__container__image__desktop__image" src={liveshopp_live} />
          </Row>
        </Col>
      </Row>
      <Row className="home__info">
        <Col className="home__info__container">
          <Row className="home__info__container__info1">Combien pouvez-vous vendre sur Liveshopp ? Il n'y a aucune
            limite
            ! <br /> Boostez vos ventes avec un minimum d'effort.</Row>
        </Col>
      </Row>
      <Row className="home__how-it-work">
        <Col className="home__how-it-work__title">Comment ça marche ?</Col>
      </Row>
      <Row className="home__validation">
        <Col className="home__validation__container">
          <Row className="home__validation__container__step">
            <Image
              className="home__validation__container__step__thumbs-up"
              src="https://em-content.zobj.net/source/microsoft-teams/337/thumbs-up_1f44d.png"
              rounded
            />
          </Row>
          <Row className="home__validation__container__title">Validez votre compte</Row>
          <Row className="home__validation__container__description">Vous avez un inventaire ? Une communauté importante
            ? Vous avez l'habitude de vendre ? Inscrivez-vous sans plus attendre ! Une fois votre candidature validée,
            accédez à Liveshopp et commencez à développer votre activité et votre communauté !
          </Row>
          <Row className="home__validation__container__ctas">
            <PrimaryButton
              variant="red-liveshopp"
              size="large"
              text="Devenir vendeur"
              onClick={() => navigate("/seller/signup")}
            />
          </Row>
        </Col>
        <Col className="home__how-it-work__validation__container">
          <Row
            className="home__how-it-work__validation__container__image">

          </Row>
        </Col>
      </Row>
      <Row className="home__validation">
        <Col className="home__how-it-work__validation__container">
          <Row
            className="home__how-it-work__validation__container__image">
          </Row>
        </Col>
        <Col className="home__validation__container">
          <Row className="home__validation__container__step">
            <Image
              className="home__validation__container__step__thumbs-up"
              src="https://em-content.zobj.net/source/microsoft-teams/337/victory-hand_270c-fe0f.png"
              rounded
            />
          </Row>
          <Row className="home__validation__container__title">Let the show begin !</Row>
          <Row className="home__validation__container__sub-title">Interagissez</Row>
          <Row className="home__validation__container__description">Engagez et Connectez-vous avec votre communauté en
            direct, en obtenant des commentaires en temps réel, des suggestions de produits, et peut-être même des
            demandes de chansons !</Row>
          <br />
          <Row className="home__validation__container__sub-title">Adjugé, Vendu !</Row>
          <Row className="home__validation__container__description">Devenez commissaire-priseur ! Fixez un prix de
            départ, une limite de temps pour le produit que vous souhaitez mettre en vente et c'est parti ! Votre public
            se battra avec le bouton d'enchère jusqu'à ce qu'un heureux enchérisseur sorte victorieux !!</Row>
        </Col>
      </Row>
      <Row className="home__validation">
        <Col className="home__validation__container">
          <Row className="home__validation__container__step">
            <Image
              className="home__validation__container__step__thumbs-up"
              src="https://em-content.zobj.net/source/microsoft-teams/337/ok-hand_1f44c.png"
              rounded
            />
          </Row>
          <Row className="home__validation__container__title">Expédiez et encaissez</Row>
          <Row className="home__validation__container__description">Empactez, envoyez, et encaissez par
            dépôt direct.</Row>
        </Col>
        <Col className="home__how-it-work__validation__container">
          <Row
            className="home__how-it-work__validation__container__image">

          </Row>
        </Col>
      </Row>
      <Row className="home__testimonials">
        <Card className="home__testimonials__testimonial">
          <Card.Body className="home__testimonials__testimonial__body">
            <Image
              className="home__testimonials__testimonial__body__user-image"
              src={liveshopp_user}
              rounded
            />
          </Card.Body>
          <Card.Body className="home__testimonials__testimonial__body">
            <blockquote className="home__testimonials__testimonial__body__blockquote">
              <p className="home__testimonials__testimonial__body__blockquote__quote">
                <span className="home__testimonials__testimonial__body__blockquote__quote__icon">
                  <FontAwesomeIcon size="2x" icon={faQuoteLeft} color="white" />
                </span>
              </p>
              <p className="home__testimonials__testimonial__body__blockquote__text">
                Liveshopp est la plateforme la plus transparente et la plus agréable pour tous les vendeurs et
                acheteurs, c'est l'avenir du shopping..{" "}
              </p>
              <footer className="home__testimonials__testimonial__body__blockquote__blockquote-footer">
                — Justine__muu
                <a className="home__testimonials__testimonial__body__blockquote__blockquote-footer__source"
                   href="https://www.instagram.com/justine_muu/?hl=fr" target="_blank">
                  <FontAwesomeIcon size="1.57x" icon={faInstagram} color="white" />
                </a>
              </footer>
            </blockquote>
          </Card.Body>
        </Card>

      </Row>
      <Row className="home__how-it-work">
        <Col className="home__how-it-work__title">Questions les plus fréquemment posées</Col>
      </Row>
      <Row className="home__faqs">
        <Accordion className="home__faqs__accordion">
          <Accordion.Item className="home__faqs__accordion__item" eventKey="0">
            <Accordion.Header className="home__faqs__accordion__item__header">Comment suis-je rémunéré
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              Vous recevrez le paiement 48 à 72 heures après la livraison du colis à l'acheteur. Ce paiement apparaîtra
              crédité avec la mention Liveshopp sur votre compte.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="1">
            <Accordion.Header className="home__faqs__accordion__item__header">Comment fonctionne la livraison
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              À la fin de chaque live, il est de votre responsabilité d'expédier les articles vendus lors de l'enchère à
              vos
              clients. < br /> Pour se faire les informations de chaque acheteur sont fournies dans votre espace pro
              Liveshopp
              dans
              l'onglet "Mes commandes"
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="2">
            <Accordion.Header className="home__faqs__accordion__item__header">Quel est le montant des frais
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              Sur le montant total de l’enchère, des frais de vente de 8% seront déduits.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="3">
            <Accordion.Header className="home__faqs__accordion__item__header">Que puis-je vendre sur Liveshopp
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              La vraie question est de savoir ce que vous ne pouvez pas vendre ici ! Nous avons actuellement plusieurs
              catégories de vente, allant des vêtements à la maroquinerie, en passant par les objets de collection.
              <br /><br />
              La vente d'articles illégaux en France ( drogue, armes ) ainsi que tout produit
              périssable est strictement interdite sur Liveshopp.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="4">
            <Accordion.Header className="home__faqs__accordion__item__header">Est-ce que Liveshopp est sécurisé
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              Oui ! Liveshopp dispose d'une équipe de support dédiée pour vous aider, vous et vos clients, à résoudre
              tout problème lié à l'utilisation de l'application. Notre priorité est de construire une marketplace et
              une communauté basée sur la confiance, la fiabilité et la sécurité.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="5">
            <Accordion.Header className="home__faqs__accordion__item__header">Comment mon profil est-il validé
              ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              Nous recherchons un ou plusieurs des éléments suivants :
              <br />
              <br />- Un historique significatif de vente via votre activité.
              <br />- Une réputation au sein de votre communauté.
              <br />- Un inventaire de qualité et en quantité suffisante pour être mis en ligne régulièrement.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="home__faqs__accordion__item" eventKey="6">
            <Accordion.Header className="home__faqs__accordion__item__header">Combien de temps faut-il pour valider mon
              profil ?</Accordion.Header>
            <Accordion.Body className="home__faqs__accordion__item__body">
              Après avoir soumis votre candidature, vous devriez recevoir au plus tard dans les deux semaines une
              confirmation par email. Si vous ne recevez pas de décision dans les deux semaines suivant l'envoi de votre
              candidature, veuillez contacter applications@liveshopp.com.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="home__info">
        <Col className="home__info__container">
          <Row className="home__info__container__info2">Commencez à générer des revenus via votre communauté dès
            maintenant.</Row>
          <Row className="home__info__container__ctas">
            <PrimaryButton
              variant="red-liveshopp"
              size="large"
              text="Devenir vendeur"
              onClick={() => navigate("/seller/signup")}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
