import React from "react"
import { Form, FloatingLabel } from "react-bootstrap"
import "./InputField.scss"

const InputField = ({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors },
                      type,
                      label,
                      placeholder,
                    }) => (

  <Form.Group className="input-field" controlId={name}>
    <Form.Label className="input-field__label">{label}</Form.Label>
    <Form.Control
      className="input-field__input"
      onChange={onChange}
      type={type}
      autoComplete="on"

      placeholder={placeholder}
    />
  </Form.Group>
)

export default InputField
