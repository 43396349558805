import axioConfig from "./config/axiosConfig"

const user = {
  handleSellerSingnUp(data) {
    return axioConfig
      .post("/api/seller/seller-signup", { data })
      .then(({ data }) => data)
      .catch(({ response }) => Promise.reject(response.data))
  },
}

export default user
