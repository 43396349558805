import React, { useState } from "react"
import "./SelectField.scss"
import { Col, DropdownButton, Form, FloatingLabel } from "react-bootstrap"

const SelectField = ({
                       field: { onChange },
                       label,
                       options,
                       name,
                     }) => {
  const [optionIsSelected, setIsSelected] = useState(false)

  return (
    <Form.Group className="select-field" controlId={name}>
      <Form.Label className="select-field__label">{label}</Form.Label>
      <Form.Select className={`select-field__select ${optionIsSelected ? "selected" : ""}`} aria-label={label}
                   onChange={(e) => {
                     onChange(e.target.value);
                     setIsSelected(true);
                   }
                   }>
        {options.map((category, key) => (
          <option style={{ color: "red", textColor: "red" }} className="select-field__select__option" key={key}
                  value={category.option}
                  disabled={category.disabled} selected={category.selected}>{category.option}</option>))}
      </Form.Select>
    </Form.Group>
  )
}

export default SelectField
