import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import PublicNav from "../PublicNav/PublicNav";
import "./Navigation.scss";

const Navigation = () => {
    return (
        <Navbar collapseOnSelect className="navigation" expand="lg">
            <Container className="navigation__container">
              <PublicNav/>
            </Container>
        </Navbar>
    );
};

export default Navigation;
