import { useState, useEffect } from "react"
import { Container, Row, Col, Form, Spinner } from "react-bootstrap"
import PrimaryButton from "../../components/Button/PrimaryButton/PrimaryButton"
import FormGroup from "../../components/FormGroup/FormGroup"
import { useForm } from "react-hook-form"
import sellerSignupForm from "../../schemas/sellerSignupForm"
import seller from "../../services/seller"
import "./SellerSignup.scss"

const SellerSignup = () => {
  const {
    handleSubmit: handleSubmit1,
    control: control1,
    reset,
  } = useForm()

  const [sellerSignupSchema, setSellerSignupSchema] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    let sellerSignupSchemaValidation = Object.keys(sellerSignupForm.fields).map(
      (key) => sellerSignupForm.fields[key],
    )
    setSellerSignupSchema(sellerSignupSchemaValidation)


  }, [])

  const handleSellerSignupForm = (data) => {
    setIsLoading(true)

    seller
      .handleSellerSingnUp(data)
      .then((res) => res)
      .then((data) => {
        setIsLoading(false)
        setMessage(data.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <Container className="seller-signup">
      <Row className="seller-signup__container">
        <Row className="seller-signup__container__form-title">Devenez vendeur sur Liveshopp</Row>

        <Col className="seller-signup__container__form">
          {message ? <Row className="seller-signup__container__form__message">{message}</Row> : ""}
          <Form className="seller-signup__container__form__content">
            <FormGroup schema={sellerSignupSchema} control={control1} />
            <Form.Group className="seller-signup__container__form__content__cta">
              <PrimaryButton
                variant="red-liveshopp"
                size="large"
                disabled={isLoading ? true : false}
                text={
                  isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Rejoindre la communauté
                    </>
                  ) : (
                    "Rejoindre la communauté"
                  )
                }
                onClick={handleSubmit1(handleSellerSignupForm)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default SellerSignup
